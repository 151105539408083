<template>
    <div id="my-calls" class="page">
      <CallsCardTemplate title="All Calls" handler="allCalls" showPagination="true" :loggedUser="user"/>
    </div>
</template>

<script>
import CallsCardTemplate from './../components/Calls/Calls.Card.Template';
export default {
    props: ['user'],
    components: {
      CallsCardTemplate
    },

    mounted(){
      this.$emit('set-sidebar', {active: null});
    },
};
</script>
